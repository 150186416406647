import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['toggleable', 'toggleable2', 'toggleable3'];

  toggle(e) {
    e.preventDefault()
    this.toggleableTargets.forEach(target => this.performToggle(target))
  }

  toggle2(e) {
    e.preventDefault()
    this.toggleable2Targets.forEach(target => this.performToggle(target))
  }

  toggle3(e) {
    e.preventDefault()
    this.toggleable3Targets.forEach(target => this.performToggle(target))
  }

  performToggle(target) {
    if (target.classList.contains('hidden')) {
      target.classList.remove('hidden')
    }
    else {
      target.classList.add('hidden')
    }
  }
}
