import { Controller } from "@hotwired/stimulus"

export default class XhrFormController extends Controller {
  static targets = [ 'form', 'success' ]

  connect() {
    this.successTarget.classList.add("hidden")
  }

  submit(e) {
    e.preventDefault()

    fetch(this.formTarget.action, {
      method: this.formTarget.method,
      body: new FormData(this.formTarget),
    })
    .then(response => {
      this.formTarget.classList.add("hidden")
      this.successTarget.classList.remove("hidden")
        // if (response.ok) {
        //     return response.json(); // Or handle response as needed
        // }
        // throw new Error('Network response was not ok.');
    })
    // .then(data => {
    //     // Handle data or redirect as per response
    // })
  }
}
