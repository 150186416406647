import { Controller } from "@hotwired/stimulus"

export default class SelectorController extends Controller {
  static targets = [ 'selectable' ]

  select(event) {
    event.preventDefault()
    this.selectableTarget.select()
  }
}
