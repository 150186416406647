import { Controller } from "@hotwired/stimulus"

export default class ClipboardController extends Controller {
  static targets = [ 'source' ]

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.value)
  }
}
