import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ 'selected', 'unselected' ]
  static targets = [ 'a', 'b', 'button' ]

  a(e) {
    e.preventDefault()
    this.aTargets.forEach(target => target.classList.remove('hidden'))
    this.bTargets.forEach(target => target.classList.add('hidden'))
    this.button_selected(e.currentTarget)
  }

  b(e) {
    e.preventDefault()
    this.bTargets.forEach(target => target.classList.remove('hidden'))
    this.aTargets.forEach(target => target.classList.add('hidden'))
    this.button_selected(e.currentTarget)
  }

  button_selected(selected_button) {
    this.buttonTargets.forEach(button => {
      if (button === selected_button) {
        button.classList.add(...this.selectedClasses)
        button.classList.remove(...this.unselectedClasses)
      }
      else {
        button.classList.remove(...this.selectedClasses)
        button.classList.add(...this.unselectedClasses)
      }
    })
  }
}
