import { Controller } from "@hotwired/stimulus"
import { loadStripe } from '@stripe/stripe-js/pure'
import { post } from 'jquery'

export default class StripeController extends Controller {
  static values = {
    pk: String,
    price: String,
    discounts: Array,
  }

  async connect() {
    this.stripe = await loadStripe(this.pkValue)
  }

  checkout(e) {
    e.preventDefault()
    post({
      url: '/stripe/sessions.json',
      dataType: 'json',
      data: {
        price_id: this.priceValue,
        discounts: this.discountsValue,
      },
      success: data => {
        console.log(data)
        this.stripe?.redirectToCheckout({ sessionId: data.session_id }).then(console.log)
      }
    })
  }
}
